import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import css from './SearchMapPriceLabel.module.css';

const { Money } = sdkTypes;

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listing,
      onListingClicked,
      isActive,
      config,
    } = this.props;
    const currentListing = ensureListing(listing);

    // Extract hourly price from listing attributes
    const hourlyPrice = currentListing.attributes.price;

    // Extract daily price from publicData
    const publicData = currentListing.attributes.publicData || {};
    const dailyPriceAmount = publicData.pricePerDayAmount;
    const dailyPriceCurrency = publicData.pricePerDayCurrency;

    // Determine which price to use
    let formattedPrice = null;

    if (hourlyPrice && hourlyPrice.currency === config.currency) {
      // Use hourly price
      formattedPrice = formatMoney(intl, hourlyPrice, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else if (dailyPriceAmount && dailyPriceCurrency === config.currency) {
      // Use daily price
      const dailyPriceSubunits = dailyPriceAmount;
      const dailyPrice = new Money(dailyPriceSubunits, dailyPriceCurrency);
      formattedPrice = formatMoney(intl, dailyPrice, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    } else if (hourlyPrice) {
      // Unsupported currency for hourly price
      formattedPrice = hourlyPrice.currency;
    } else if (dailyPriceCurrency) {
      // Unsupported currency for daily price
      formattedPrice = dailyPriceCurrency;
    } else {
      // No price available
      formattedPrice = null;
    }

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, {
      [css.mapLabelActive]: isActive,
      [css.noPriceSetLabel]: !formattedPrice,
    });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    return  formattedPrice ? (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        <div className={css.caretShadow} />
        <div className={priceLabelClasses}>{formattedPrice}</div>
        <div className={caretClasses} />
      </button>
    ) : null;
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);